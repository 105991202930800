import { enviroment } from "../interfaces/enviroment";
import { ClaveValor } from "../interfaces/claveValor";

const BASE_URL: string = enviroment.urlBase() + "api/Parametro";
const ENV_DEMO: boolean = enviroment.demo();

export const GetParameters = async (): Promise<ClaveValor[]> => {
    const url = `${BASE_URL}`;
    try {
        const response = await fetch(url);
        if (!response.ok){
            throw new Error(response.statusText);
        }
        const data: ClaveValor[] = await response.json();
        return data;        
    } catch (error) {
        return [];
    }
}

export const GetParameterByKey = async (param?: string): Promise<string | undefined> => {
    const url = `${BASE_URL}?${param}`;
    try {
        const response = await fetch(url);
        if (!response.ok){
            throw new Error(response.statusText);
        }
        const data: ClaveValor[] = await response.json();
        return data[0].valor;        
    } catch (error) {
        return undefined;
    }
}